import { Text } from "@otrium/atoms";
import { Box, useBreakpoints } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { AuthModal } from "src/molecules/AuthModal";
import { AuthType } from "src/molecules/AuthModal/AuthModal";
import {
  ButtonsWrapper,
  SubHeading,
  StyledButton,
  Wrapper,
  LockedUnLockedIcon,
  Title,
} from "./AuthModule.styled";
import { SEGMENT_IN_VIEW_THRESHOLD } from "src/segment";
import { useSegment } from "src/hooks/useSegment";
import { PLPType } from "src/types/PLPType";
import { HomePageType } from "src/types/HomePageType.d";
import classNames from "classnames";
import { LockUnlockIcon } from "@otrium/icons";
import { useTheme, Theme } from "@emotion/react";
import { Locked } from "src/icons/Locked";
import { UnLocked } from "src/icons/UnLocked";

const AuthModule = ({
  trackingData,
  noMarging,
}: {
  trackingData?: {
    pageType?: PLPType | HomePageType;
    categorySlug?: string;
    pageName?: string;
    brand?: string;
    shopType?: string;
  };
  noMarging?: boolean;
}): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [authType, setAuthType] = useState<AuthType>(AuthType.SIGN_IN);
  const {
    segmentViewedAuthBanner,
    segmentAuthBannerClosed,
    segmentAuthBannerSelect,
  } = useSegment();
  const theme: Theme = useTheme();
  const { isMobile } = useBreakpoints();

  const [ref] = useInView({
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        void segmentViewedAuthBanner(trackingData?.pageName);
      }
    },
  });

  return (
    <>
      <Wrapper
        ref={ref}
        className={classNames({
          ["no-margin"]: noMarging,
          ["hovered"]: isHovered,
        })}
      >
        <LockedUnLockedIcon>
          {isHovered ? (
            <UnLocked
              color={theme.colors.tone.white}
              width={52}
              height={52}
              role="presentation"
            />
          ) : (
            <Locked
              color={theme.colors.tone.black}
              width={52}
              height={52}
              role="presentation"
            />
          )}
        </LockedUnLockedIcon>
        <Box>
          <Title
            fontSize={"24px"}
            fontFamily="PlusJakartaSans"
            lineHeight={"32px"}
            pb={0}
            isHovered={isHovered}
          >
            <Trans>auth_banner_title</Trans>
          </Title>
          <SubHeading className={isHovered ? "hovered" : ""}>
            <Text
              variant="body.source.regular18"
              color="black"
              ml={1}
              as="span"
              letterSpacing={0.1}
            >
              <Trans>auth_banner_body</Trans>
            </Text>
          </SubHeading>
        </Box>
        <ButtonsWrapper>
          <StyledButton
            variant={"secondary"}
            colorScheme={"dark"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            leftIcon={
              isMobile ? (
                <LockUnlockIcon color={theme.colors.tone.black} />
              ) : null
            }
            onClick={() => {
              setAuthType(AuthType.SIGN_IN);
              setOpen(true);
              void segmentAuthBannerSelect({
                type: "sign_in",
                page_type: trackingData?.pageType,
                category_slug: trackingData?.categorySlug,
                shop_type: trackingData?.shopType,
                ...(trackingData?.brand
                  ? { brand: trackingData?.brand }
                  : { category: trackingData?.pageName }),
              });
            }}
          >
            <Trans>Log in</Trans>
          </StyledButton>
          <StyledButton
            variant={"primary"}
            colorScheme={"dark"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            leftIcon={
              isMobile ? (
                <LockUnlockIcon color={theme.colors.tone.white} />
              ) : null
            }
            onClick={() => {
              setAuthType(AuthType.SIGN_UP);
              setOpen(true);
              void segmentAuthBannerSelect({
                type: "sign_up",
                page_type: trackingData?.pageType,
                shop_type: trackingData?.shopType,
                category_slug: trackingData?.categorySlug,
                ...(trackingData?.brand
                  ? { brand: trackingData?.brand }
                  : { category: trackingData?.pageName }),
              });
            }}
          >
            <Trans>Register</Trans>
          </StyledButton>
        </ButtonsWrapper>
      </Wrapper>
      {open && (
        <AuthModal
          open={open}
          authType={authType}
          onAuthTypeChange={(newAuthType) => setAuthType(newAuthType)}
          origin="auth-banner"
          onClose={() => {
            void segmentAuthBannerClosed({
              popup_type: authType === AuthType.SIGN_IN ? "sign_in" : "sign_up",
              page_type: trackingData?.pageType,
              category_slug: trackingData?.categorySlug,
              shop_type: trackingData?.shopType,
              ...(trackingData?.brand
                ? { brand: trackingData?.brand }
                : { category: trackingData?.pageName }),
            });
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AuthModule;
