import styled from "@emotion/styled";
import { Heading } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import { Button } from "src/atoms/Button";

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: ${({ theme }) => theme.space.space16};
  align-items: unset;
  border-radius: ${({ theme }) => theme.space.space4};
  margin: 0 ${({ theme }) => theme.space.space16};
  border: 1px solid #eeeeee;
  transition: background-color 0.5s ease-in-out;

  &.no-margin {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) => theme.space.space16};
    margin: 0;
    flex-direction: row;
    align-items: center;
  }

  @media (hover: hover) {
    &.hovered {
      background-color: ${({ theme }) => theme.colors.tone.black};

      button:first-of-type:not(button:first-of-type:hover) {
        background-color: ${({ theme }) => theme.colors.tone.black};
        color: ${({ theme }) => theme.colors.tone.white};
        border-color: ${({ theme }) => theme.colors.tone.white};
      }
    }
  }
`;

const ButtonsWrapper = styled(Flex)`
  flex-shrink: 0;
  flex-flow: row;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-left: auto;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: unset;
  min-height: ${({ theme }) => theme.space.space48};
  height: auto;
  border: 2px solid ${({ theme }) => theme.colors.tone.black};
  transition: all 0.2s ease-in-out;

  &:first-of-type {
    margin-right: ${({ theme }) => theme.space.space16};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: auto;
  }

  @media (hover: hover) {
    &:first-of-type {
      &:hover {
        background-color: ${({ theme }) => theme.colors.tone.white};
        color: ${({ theme }) => theme.colors.tone.black};
        border-color: ${({ theme }) => theme.colors.tone.white};

        + button {
          border-color: ${({ theme }) => theme.colors.tone.white};
        }
      }
    }

    &:last-of-type {
      &:hover {
        background-color: ${({ theme }) => theme.colors.tone.white};
        color: ${({ theme }) => theme.colors.tone.black};
        border-color: ${({ theme }) => theme.colors.tone.white};
      }
    }
  }

  span {
    align-content: center;
  }
`;

const Title = styled(Heading)<{ isHovered: boolean }>`
  @media (hover: hover) {
    color: ${({ isHovered, theme }) =>
      isHovered ? theme.colors.tone.white : theme.colors.tone.black};
  }
`;

const SubHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  color: ${({ theme }) => theme.colors.tone.white};
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.space.space8} 0;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-bottom: ${({ theme }) => theme.space.space16};
  }

  span {
    margin: 0;
  }

  @media (hover: hover) {
    &.hovered span {
      color: ${({ theme }) => theme.colors.tone.white};
    }
  }
`;

const LockedUnLockedIcon = styled("div")`
  display: none;
  padding-right: ${({ theme }) => theme.space.space16};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
  }
`;

export {
  Wrapper,
  Title,
  SubHeading,
  ButtonsWrapper,
  StyledButton,
  LockedUnLockedIcon,
};
