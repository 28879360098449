import { ReactNode, createContext, useMemo, useReducer } from "react";
import {
  createReducer,
  Dispatch,
  ReducerAction,
} from "src/utils/create-reducer";
import { Promotion, PromotionDisplayType } from "src/types/graphql.d";

export interface PromotionContextState {
  hasPromotionEnded?: boolean;
  promotion?: Promotion;
  promotionDisplayType?: PromotionDisplayType;
  promotionEndDate?: string;
  promotionStartDate?: string;
  promotionMessage?: string;
}

export type PromotionContextActions =
  | ReducerAction<"SET_PROMO", PromotionContextState>
  | ReducerAction<"SET_PROMO_STATUS", boolean>;

export const PromotionContext = createContext<
  | {
      promotionContextState: PromotionContextState;
      promotionContextDispatch: Dispatch<PromotionContextActions>;
    }
  | undefined
>(undefined);

export const promotionContextReducer = createReducer<
  PromotionContextState,
  PromotionContextActions
>({
  SET_PROMO: (state, payload) => ({ ...state, ...payload }),
  SET_PROMO_STATUS: (state, payload) => ({
    ...state,
    hasPromotionEnded: payload,
  }),
});

export function PromotionContextProvider({
  children,
  initialState = {
    hasPromotionEnded: false,
    promotionDisplayType: PromotionDisplayType.Default,
    promotionEndDate: "",
    promotionStartDate: "",
    promotionMessage: "",
  },
}: {
  children: ReactNode;
  initialState?: PromotionContextState;
}): JSX.Element {
  const [promotionContextState, promotionContextDispatch] = useReducer(
    promotionContextReducer,
    initialState
  );

  const promotionContextValue = useMemo(
    () => ({ promotionContextState, promotionContextDispatch }),
    [promotionContextState]
  );

  return (
    <PromotionContext.Provider value={promotionContextValue}>
      {children}
    </PromotionContext.Provider>
  );
}
