import { FC, SVGAttributes } from "react";

const UnLocked: FC<SVGAttributes<SVGSVGElement>> = ({
  color,
  width = "52",
  height = "52",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1666 21.6667V17.3333C15.1666 11.3502 20.0169 6.5 26 6.5C30.4423 6.5 34.2602 9.17387 35.9319 13M26 31.4167V35.75M19.0666 45.5H32.9333C36.5736 45.5 38.3938 45.5 39.7842 44.7915C41.0073 44.1684 42.0017 43.174 42.6248 41.9509C43.3333 40.5605 43.3333 38.7403 43.3333 35.1V32.0667C43.3333 28.4263 43.3333 26.6062 42.6248 25.2157C42.0017 23.9927 41.0073 22.9983 39.7842 22.3751C38.3938 21.6667 36.5736 21.6667 32.9333 21.6667H19.0666C15.4263 21.6667 13.6061 21.6667 12.2157 22.3751C10.9926 22.9983 9.99826 23.9927 9.37508 25.2157C8.66663 26.6062 8.66663 28.4263 8.66663 32.0667V35.1C8.66663 38.7403 8.66663 40.5605 9.37508 41.9509C9.99826 43.174 10.9926 44.1684 12.2157 44.7915C13.6061 45.5 15.4263 45.5 19.0666 45.5Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { UnLocked };
